<template>
  <div class="avatar" :style="`background:${getBackground}`">
    {{ initials }}
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: 'Avatar',
  props: {
    firstName: {
      type: String,
      default: null,
    },
    lastName: {
      type: String,
      default: null,
    },
  },
  setup() {
    const userStore = useUserStore()

    return { user: userStore }
  },
  computed: {
    backgroundColors() {
      return [
        this.$vuetify.theme.themes.light['avatar-0'] as string,
        this.$vuetify.theme.themes.light['avatar-1'] as string,
        this.$vuetify.theme.themes.light['price-plan-gold'] as string,
        this.$vuetify.theme.themes.light['avatar-3'] as string,
        this.$vuetify.theme.themes.light['avatar-4'] as string,
        this.$vuetify.theme.themes.light['avatar-5'] as string,
        this.$vuetify.theme.themes.light['avatar-6'] as string,
        this.$vuetify.theme.themes.light['avatar-7'] as string,
        this.$vuetify.theme.themes.light['avatar-8'] as string,
        this.$vuetify.theme.themes.light['avatar-9'] as string,
      ]
    },
    initials() {
      if (this.firstName && this.lastName) {
        // both names have to be given when not the logged in user shall be taken
        return `${this.firstName.substring(0, 1)}${this.lastName.substring(0, 1)}`
      } else if (this.user?.firstName && this.user?.lastName) {
        return `${this.user.firstName.substring(0, 1)}${this.user.lastName.substring(0, 1)}`
      } else {
        return ''
      }
    },
    getFullName() {
      // both names have to be given when not the logged in user shall be taken
      if (this.firstName && this.lastName) {
        return this.firstName + this.lastName
      } else if (this.user?.firstName && this.user?.lastName) {
        return this.user.firstName + this.user.lastName
      } else {
        return ''
      }
    },
    getBackground() {
      let hash = 0
      let i: number
      let chr: number

      // Set default color
      if (this.getFullName.length === 0) {
        return this.$vuetify.theme.themes.light['avatar-0'] as string
      }

      // set hash based color
      for (i = 0; i < this.getFullName.length; i++) {
        chr = this.getFullName.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        // Convert to 32bit integer
        hash |= 0
      }

      return this.backgroundColors[Math.abs(hash % 10)]
    },
  },
})
</script>

<style lang="sass">

$ns: avatar

.#{$ns}
  position: relative
  display: flex
  align-items: center
  justify-content: center
  width: 40px
  height: 40px
  border-radius: 50%
  background-color: $c-avatar-0
  color: $c-white
  font-weight: bold
</style>
